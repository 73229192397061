<template>
  <div class="cate-orange">
    <!-- title -->
    <h2>お気に入り</h2>

    <!-- message data empty -->
    <b v-if="pagination.total === 0">該当するデータが見つかりませんでした。</b>

    <!-- list favorite adv -->
    <ListCategory :list="favoriteList"
                  :type-color="typeColor"/>

    <!-- pagination -->
    <Pagination :total-page="pagination.totalPages"
                @change-page = changePage
                :current-page="pageActive" />
  </div>
</template>

<script>
import ListCategory from '@/components/ListCategory'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/Pagination'
import Common from '@/mixins/common.mixin'

export default {
  name: 'FavoriteAds',

  components: { Pagination, ListCategory },

  computed: {
    ...mapState('favoriteAds', ['favoriteList', 'pagination'])
  },

  created () {
    const params = {
      perPage: 16,
      page: 1,
      with: 'advertise'
    }
    store.dispatch('favoriteAds/getListFavorite', params)

    this.pageActive = this.pagination.currentPage
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      typeColor: 'cate-orange',
      params: {
        perPage: 16,
        page: 1,
        with: 'advertise'
      },
      pageActive: 1
    }
  },

  methods: {
    ...mapActions('favoriteAds', ['getListFavorite']),

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.getListFavorite(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}
</style>
